import './Common.css'

function Header(){
    return (
        <div className="header">
            <h1>Groovebot</h1>
        </div>
    )
};

export default Header