import React, { useState, useEffect } from 'react';
import classes from './Forms.module.css';

function AuditForm() {
  const [referenceObjects, setReferenceObjects] = useState([]);
  const [comparisonObjects, setComparisonObjects] = useState([]);

  useEffect(() => {
    async function fetchAuditObjects() {
      const response = await fetch('https://crj2uwhhf8.execute-api.us-east-1.amazonaws.com/api/audit');
      const data = await response.json();
      console.log(data);
      setReferenceObjects(Object.entries(data.referenceObjects));
      setComparisonObjects(Object.entries(data.comparisonObjects));
    }

    fetchAuditObjects();
  }, []);

  return (
    <React.Fragment>
      {referenceObjects.length === 0 ? (
        <p>Loading, hang tight....</p>
      ) : (
        <div className={classes['new-item__controls']}>
          <div className={classes['new-item__control']}>
            <label htmlFor="referenceArray">Select A Reference Roster</label>
            <select id="referenceArray">
              {referenceObjects.map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className={classes['new-item__control']}>
            <label htmlFor="comparisonArray">Choose an App to Audit</label>
            <select id="comparisonArray">
              {comparisonObjects.map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default AuditForm;
