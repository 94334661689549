import classes from './Forms.module.css';

function UploadForm() {
   return (
    <div claasName={classes["new-item__controls"]}>
            <div className={classes["new-item__control"]}>
                <input type="file" />
            </div>
    </div>
   );
};

export default UploadForm