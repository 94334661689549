import React, { useState, useEffect } from 'react';
import NewAPI from '../Form_Actions/API_New';
import UpdateAPI from '../Form_Actions/API_Update';
import DeleteAPI from '../Form_Actions/API_Delete';
import classes from './Forms.module.css';

function APIForm() {
    const [actionSelected, setActionSelected] = useState('');
    const [formActionElement, setFormActionElement] = useState(null);

    useEffect(() => {
      switch (actionSelected) {
        case 'newAPI':
          setFormActionElement(<NewAPI />);
          break;
          case 'updateAPI':
            setFormActionElement(<UpdateAPI />);
          break;
        case 'deleteAPI':
          setFormActionElement(<DeleteAPI />);
          break;
        default:
          setFormActionElement(null);
      }
    }, [actionSelected]);
    
    function actionHandler(event){
        setActionSelected(event.target.value);
    };

    return (
      <div className={classes["new-item__controls"]}>
        <div className={classes["new-item__control"]}>
          <label htmlFor="apiActions">What would you like to do?</label>
          <select name="apiActions" id="apiActions" onChange={actionHandler}>
            <option value="default">Please make a selection</option>
            <option value="newAPI">Configure a new integration</option>
            <option value="updateAPI">Modify an existing integration</option>
            <option value="deleteAPI">Delete an integration</option>
          </select>
          {formActionElement}
        </div>  
      </div>
    );
}

export default APIForm;
