import React, { useState, useEffect } from 'react';

function DeleteAPI() {
  const [data, setData] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('https://crj2uwhhf8.execute-api.us-east-1.amazonaws.com/api/integrations');
      const jsonData = await response.json();
      setData(JSON.parse(jsonData.body));
    }
    fetchData();
  }, []);

  function handleProviderChange(event) {
    setSelectedProvider(event.target.value);
  }

  return (
    <div>
      {data ? (
        <div>
          <label htmlFor="providerSelect">Select provider:</label>
          <select id="providerSelect" onChange={handleProviderChange}>
            <option value="">-- Select provider --</option>
            {data.map(provider => (
              <option key={provider.providerName} value={provider.providerName}>{provider.providerName}</option>
            ))}
          </select>
        </div>
      ) : (
        <p>Loading, please hang tight...</p>
      )}
    </div>
  );
}

export default DeleteAPI;
