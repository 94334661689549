import React, { useState } from 'react';
import classes from './Items.module.css';
import APIForm from '../Forms/Form_API';
import AuditForm from '../Forms/Form_Audit';
import UploadForm from '../Forms/Form_Upload';

function FormCard({ title, formComponent, onHide }) {
  return (
    <div className={classes['card-item__sub']}>
      <h3>{title}</h3>
      {formComponent}
      <button type="button" onClick={onHide}>Submit</button>
    </div>
  );
}

function ActionsItem() {
  const [visibleForm, setVisibleForm] = useState(null);

  function handleButtonClick(event) {
    const id = event.target.id;
    setVisibleForm(id === visibleForm ? null : id);
  }

  const hideForm = () => setVisibleForm(null);

  const formProps = {
    onHide: hideForm
  };

  return (
    <div className={classes['card-item']}>
      <h3>Actions</h3>
            
      <button id="showUploadForm" onClick={handleButtonClick}>Upload a Roster</button>
      <button id="showApiForm" onClick={handleButtonClick}>Configure API</button>
      <button id="showAuditForm" onClick={handleButtonClick}>Configure An Audit</button>

      {visibleForm && visibleForm === "showApiForm" && (
        <FormCard
          title="API Configuration"
          formComponent={<APIForm />}
          {...formProps}
        />
      )}

      {visibleForm && visibleForm === "showUploadForm" && (
        <FormCard
          title="Upload a Roster"
          formComponent={<UploadForm />}
          {...formProps}
        />
      )}

      {visibleForm && visibleForm === "showAuditForm" && (
        <FormCard
          title="Configure an Audit"
          formComponent={<AuditForm />}
          {...formProps}
        />
      )}

    </div>
  );
}

export default ActionsItem;
