import classes from './Items.module.css';

function KPIDashboardItem(){
    return (
        <div className={classes['card-item']}>
        <h3>KPI Dashboard</h3>
      </div>
    );
};

export default KPIDashboardItem;