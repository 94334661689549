
import Footer from './Components/Layout/Footer';
import Header from './Components/Layout/Header';
import AccessSection from './Components/Modules/Access/AccessSection';

function App() {
  return (
    <div>
      <Header />
      <AccessSection />
      <Footer />
    </div>
  );
}

export default App;
