import classes from './Items.module.css';

function ResultsItem () {
    return(
        <div className={classes['card-item']}>
            <h3>Results</h3>
        </div>
    );
};

export default ResultsItem;