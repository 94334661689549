//Access.js === Expenses.js
//AccessSection.js === ExpenseList.js
//AccessItem === ExpensesItem.js

import "./AccessSection.css"
import Card from "../../UI/Card";
import KPIDashboardItem from "./Items/Item_KPIDashboard";
import ActionsItem from './Items/Item_Actions';
import ResultsItem from "./Items/Item_Results";

function AccessSection() {
    return (
        <Card >
            <h2>Access Management</h2>
            <div className="section-list">
                <KPIDashboardItem />
                <ActionsItem />
                <ResultsItem />

            </div>    
        </Card>        
    )
};

export default AccessSection